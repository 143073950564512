<script setup lang="ts">
import Alert from '@/components/core/notification/Alert.vue'

useHead({
  title: 'Maplerad Dashboard',
  link: [{ rel: 'icon', type: 'image', href: '/favicon.png' }],
})

const props = useAttrs()
const router = useRouter()
const route = useRoute()
const { locale, locales, setLocale } = useI18n()

const hasDashboardImg = (props['has-dashboard-img'] as string) || false
const fullView = (props['full-view'] as string) || false

const handleCommand = (item: string) => {
  router.push({
    path: route.path,
    query: { ...route.query, locale: item },
  })
}

const features = [
  {
    title: 'features.multicurrencyTitle',
    subtitle: 'features.multicurrencySubtitle',
    icon: 'multi-wallet',
  },
  {
    title: 'features.payoutTitle',
    subtitle: 'features.payoutSubtitle',
    icon: 'currency-exchange',
  },
  {
    title: 'features.virtualCardTitle',
    subtitle: 'features.virtualCardSubtitle',
    icon: 'card-icon',
  },
  {
    title: 'features.paymentTitle',
    subtitle: 'features.paymentSubtitle',
    icon: 'payment',
  },
]

onMounted(() => {
  if (!route.query.locale) {
    // Todo: ensure that local is set on log in
    router.push({
      path: route.path,
      query: { ...route.query, locale: 'en' },
    })
  }
})

watch(
  () => route.query.locale,
  (newValue) => {
    setLocale(newValue as string)
  },
)
</script>
<template>
  <main>
    <Alert />
    <div class="h-screen">
      <div class="flex">
        <section
          class="h-screen overflow-x-hidden flex justify-between w-full flex-col bg-white"
          :class="{ 'lg:w-1/2': !fullView }"
        >
          <p class="py-12 pl-12 relative">
            <Icon
              name="logo-new"
              class="h-40 absolute -top-8"
            />
          </p>

          <div class="px-4 sm:px-0 max-w-[460px] w-full mx-auto flex flex-col gap-8">
            <slot />
          </div>

          <div class="self-center flex justify-between gap-8 py-8">
            <Typography
              class="hover:underline"
              color="text-gray500"
              as="NuxtLink"
              target="_blank"
              to="https://wirepay.notion.site/Maplerad-Terms-of-Use-Privacy-Policy-346259d44725482c829b10f516400f14"
            >
              {{ $t('auth.legalPrivacy') }}
            </Typography>
            <Typography
              class="hover:underline"
              color="text-gray500"
              as="NuxtLink"
              target="_blank"
              to="https://intercom.help/maplerad/en"
            >
              {{ $t('auth.helpCenter') }}
            </Typography>
            <Dropdown
              popper-class="-mt-1"
              trigger="click"
              @command="handleCommand"
            >
              <button class="flex gap-1.5 items-center">
                <Typography
                  color="text-gray500"
                  class="uppercase"
                >
                  {{ locale }}
                </Typography>
              </button>
              <template #dropdown>
                <DropdownItem
                  v-for="item in locales"
                  :key="item.code"
                  :command="item.code"
                >
                  {{ item.name }}
                </DropdownItem>
              </template>
            </Dropdown>
          </div>
        </section>

        <div
          class="h-screen flex-col justify-between hidden"
          :class="{ 'lg:flex lg:w-1/2': !fullView }"
        >
          <img
            v-if="hasDashboardImg"
            src="/dashboard.svg"
            class="h-[700px] ml-auto mt-16"
          />

          <template v-else>
            <div />
            <div class="max-w-lg w-full mx-auto flex flex-col gap-12">
              <div
                v-for="feature in features"
                :key="feature.title"
                class="flex gap-4 items-center"
              >
                <Icon
                  :name="feature.icon"
                  class="h-24 w-24"
                />
                <div class="flex-1">
                  <Typography
                    variant="lgBold"
                    color="text-gray900"
                    class="mb-2"
                    >{{ $t(feature.title) }}</Typography
                  >
                  <Typography
                    variant="base"
                    color="text-gray500"
                    >{{ $t(feature.subtitle) }}</Typography
                  >
                </div>
              </div>
            </div>
          </template>

          <Typography
            color="text-gray500"
            variant="sm"
            class="text-center pb-8 justify-self-end"
            >{{ $t('auth.copyright') }}
          </Typography>
        </div>
      </div>
    </div>
  </main>
</template>
